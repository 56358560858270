.financing-form {
    padding-left: 3px;

    .financing-caixa {
        display: flex;
        align-items: center;
        gap: 2rem;

        .form-label {
            margin: 0 0 0 5px;
            font-size: 14px;
        }
    }
    .financing-bb {
        margin: 0;
        display: flex;
        align-items: center;
        padding: 10px 0 0 0;

        .form-label {
            margin: 3px 0 0 5px;
            font-size: 14px;
        }
    }
    .form-group {
        input[type="radio"] {
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
    .check-invalid {
        position: relative;
        left: -1rem;
        margin: -5px 0 3px;
        font-size: 12px;
    }
    .form-apf {
        margin-bottom: 1.5rem;

        .label-apf {
            font-size: 10px;
            display: flex;
            gap: 4px;
            align-items: center;
            svg {
                margin-bottom: 1.5px;
                opacity: .9;
            }
        }
        input {
            width: 100%;
            @media (min-width: 700px) {
                width: 50%;
            }
            @media (min-width: 1200px) {
                width: 40%;
            }
        }
    }
}