
.tela-login {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    background-color: rgba(0, 0, 0, 0.298); // Adiciona uma cor de fundo semi-transparente
    background-blend-mode: darken;

    .login-container {
        background-color:#ffffff;
    }

    .logo-img-title{
        max-width: 200px;
        height: auto;
        width: 180px;
    }

    .card-title-logo {
        text-align: center;
        cursor: default;
    }

    .modal-forgot-login-data {
        float: right;
        margin: 3px -1rem 8px 0;
        .btn-forgot-login-data {
            margin: 0;
            color: #1e73c7;
            font-size: 12px;
            cursor: pointer;
            &:hover {
                color: #1960a6;
            }
        }
    }

    @media (max-width: 991.9px) {
        .login-container {
            background-color: transparent;

            .login-auth-area {
                background-color: #ffffff;
                padding: 2rem;
                border-radius: 10px;

                .logo-img-title{
                    width: 155px;
                }
            }
        }
    }
}





