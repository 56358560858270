@import './status';
@import './data';
@import './card';
@import '../../variables/breakpoints';
@import '../../variables/mixins';
@import './seeProposal';
@import './allDocuments';

.no-results {
    gap: 1.5rem;
    border-radius: 10px;
    span {
        font-weight: 500;
        font-size: 14px;
    }
    img {
        max-width: 200px;
    }
}
.btn-new-quotation {
    max-width: 120px;
    padding: 6px;
    align-self: flex-end;
    margin: 5px 1.7rem 0 1.7rem;
}
.list-quotations {
    max-width: 100%;
    padding: 1rem;
    .card-quotation {
        box-shadow: 0 0 3px 2px #cfcfcf63;
        .status-quotation {
            .MuiBadge-badge {
                min-width: 8px !important;
                height: 0 !important;
                padding: 4px !important;
                background-color: inherit !important;
            }
        }
    }
    @include for-max-md {
        width: 100%;
    }
    @include for-min-md {
        width: 70%;
    }
    @include for-min-lg {
        width: 50%;
    }
}

.details-ComingIn {
    animation: detailsComingIn .2s;
}
.details-ComingOut {
    animation: detailsComingOut .2s;
}

@keyframes detailsComingIn {
    from {
        transform: translateX(5000px);
    } to {
        transform: translateX(0);
    }
}
@keyframes detailsComingOut {
    from {
        transform: translateX(0);
    } to {
        transform: translateX(5000px);
    }
}

.content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

 // titulo
 .goBack-container {
    display: flex;
    border-bottom: solid #6b6b6b18 2px;
    margin-bottom: 1rem;
    background-color: #ffff;
    h4 {
        margin-bottom: 0;
    }
}

.result-rcpm-container {
    .card-header {
        margin-top: .8rem;
        .upper-title {
            width: 100%;
            text-align: center;
            margin: 0;
            h1 {
                font-size: 24px;
                color: #1976d2;
            }

            .title-description {
                display: inline-flex;
                align-items: center;
                gap: 5px;
                h2, svg {
                    margin: 0;
                }
                h2 {
                    font-size: 15px;
                }
            }
        }
    }

    .pagination-container {
        width: 100%;
    }

    .title-section {
        h2 {
            font-size: 20px;
            font-weight: 500;
            margin: 0;
        }
        .spinner-grow {
            margin-left: 8px;
            margin-top: 1px;
            border: solid 3px #8ab4df;
            max-width: 11px;
            max-height: 11px;
        }
        svg {
            margin-left: 5px;
            margin-top: 3px;
            width: 17px;
            height: 17px;
        }
    }
    .card-body {
        margin-bottom: 0;
    }

    //estilizando paginação
    .pagination-container {
        margin: 0 !important;
        width: 100% !important;
        position: sticky;
        bottom: 0;
        left: 0;

        ul {
            width: 100% !important;
            margin: 0;
            border-radius: 0;
            gap: 2%;
            background: #f3f2f7;
            box-shadow: 3px 0px 7px 0px rgba(0, 0, 0, 0.189);
            padding: 1px 0;

            li {
                a {
                    border-radius: 50% !important;
                    font-weight: 500;
                    color: #343b47 !important;

                    &:hover {
                        background: #9da8b964 !important;
                    }
                }
            }
            //quando tiver selecionado
            .active {
                background: transparent !important;
                a {
                    background: $primary !important;
                    transform: scale(1.11);
                    position: relative;
                    bottom: 3.1px;
                    &:hover {
                        background: $primary !important;
                    }
                }
            }
        }    
    }
}

.dark-layout {
    .goBack-container {
        background-color: #283046;
        svg {
            &:hover {
                background-color: rgb(65, 71, 86);
                border-radius: 50%;
                border: solid 1px #d1d1d17d;
            }
        }
    }
    .pagination-container {
        ul {
            background: #2d354d;
            box-shadow: 3px 0px 7px 0px rgba(184, 184, 184, 0.189);
  
            li {
                a {
                    color: #aeaeae !important;
                    background: #242b3d !important;
                    &:hover {
                        background-color: #bfbebe37 !important;
                    }
                }
            }
            //quando tiver selecionado
            .active {
                a {
                    background-color: #4080c1 !important;
                }
            }
        }
    }
}