@import '../../variables/breakpoints';
@import '../../variables/mixins';

.quotation-media-list {
    width: 100%;
    margin: 0 0 4rem 0;
    padding: 0;

    .quotation-card-container {
        &:not(:first-child) {
            margin: 10px 0;
        }
        width: 100%;
        padding: 10px;
        box-shadow: 0px 0px 2px 1px #7f7e7e3f;
        cursor: default;
        gap: 1.8rem;
        position: relative;
        border-radius: 5px;

        &:hover {
            box-shadow: 0px 0px 2px 1px #277ab65f;
            background-color: #d8d8d829;
            h1 {
                color: $primary;
            }
        }

        .quotation-card {
            width: 100%;

            .quotation-status {
                display: flex;
                justify-content: flex-end;
                font-size: 12px;
                font-weight: 500;

                i {
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                }
            }

            div:first-child {
                margin-right: 10px
            }

            .group {
                div {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    h1 {
                        font-size: 15px;
                        @include for-large-mobile {font-size: 14px;}
                        @include for-small-mobile {
                            font-size: 13px;
                            font-weight: 550;
                        }
                        svg:hover {
                            color: $orange-strong !important;
                        }
                    }
                    h2 {
                        font-size: 12px;
                        margin: 0;
                        font-weight: 600;
                    }
                    h3 {font-size: 12px;}

                    .icon-title-lead {
                        font-size: 13px;
                        margin: 0 -3px 8px 0;
                    }
                    .quotation-status {
                        font-size: 12px;
                    }                   
                }
                .quote-values {
                    display: flex !important;
                    flex-wrap: wrap !important;
                    justify-content: flex-start !important;
                    .property-value svg {
                        margin-right: 0;
                        margin-top: -3px;
                        color: $blue-primary;
                    }
                    .quotation-value {
                        margin-left: 1rem;
                        svg {
                            color: $green;
                            margin-right: -2px;
                        }
                    }
                    .insurance-name {
                        margin-left: 1rem;
                    }
                }
            }

            @include for-small-mobile { 
                flex-direction: column;
                align-items: flex-start !important;
                h1 {
                    margin-bottom: 3px;
                }
                h2 {
                    margin-bottom: 3px;
                }
                .group {
                    h1 {
                        margin-bottom: 5px;
                    }
                    &:first-child {
                        margin-bottom: 5px;
                        flex-direction: column;
                    }
                    &:last-child {
                        flex-direction: row !important;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        svg, h3 {
                            margin-bottom: 0;
                        }
                    }
                    .quote-values {
                        .quotation-value, .insurance-name {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.dark-layout {
    .quotation-card-container {
        box-shadow: 0 0 2px 2px  rgba(172, 172, 172, 0.14);
        &:hover {
            background-color: #6f6f6f29;
        }
    }
}
