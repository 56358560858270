.propertyData-form {

    .property-address {
        border-left: solid 2px #1e72c7de;
        padding-left: 1.7rem;
        width: max-content;
    }
    .form-group-propertyData {
        display: flex;
        gap: 1rem;
        min-width: 100%;
        div {
            width: 100%;
        }
        @media (max-width: 700px) {
            flex-wrap: wrap;
        }
    }

    .form-group {
        // width: auto !important;
        input[type="radio"] {
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
    .check-invalid {
        position: relative;
        left: -1rem;
        margin: -5px 0 3px;
        font-size: 12px;
    }

    .address-group {
        display: flex;
        gap: 1rem;
        width: 100%;

        .address-form {
            width: 80%;
        }
        .addressNumber-form {
            width: 20%;
        }
    }

    .radio-options-part {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .radio-options-type {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .option {
        display: flex;
        align-items: center;
        gap: 2rem;

        .form-label {
            margin: 0 0 0 20px;
        }

        input {
            margin: 0;
        }
    }

    .label-lisenseMom {
        display: flex;
        gap: 6px;
        align-items: center;
        svg {
            font-size: 10px;
            opacity: .9;
        }
    }
    
    .property-units-container {
        .primereact-input-number {
            input {
              margin-right: 2px;
              padding: 7px;
              outline-color: #1e73c7 !important;
              border: solid 1px #4747472f;
              border-radius: 3px;
              margin-top: 2px;
            }
            button {
              border: none;
              padding: 0 4px;
              background-color: #1e73c7;
              color: #fff;
              border-radius: 3px;
              margin-left: 1.4px;
              max-height: 17px;
              &:first-child {
                margin-top: 2px;
                svg {
                    margin-top: -1px;
                }
              }
              &:last-child {
                margin-top: 1px;
                svg {
                    margin-bottom: -1px;
                }
              }
            }
        }
    }
}

.dark-layout {
    .primereact-input-number input {
        background-color: transparent !important;
        border: solid 1px #c7c7c74f !important;
        color: #c0c0c0 !important;
    }
}