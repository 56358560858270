//Breakpoints
@mixin for-large-mobile {
    @media (min-width: 501px) and (max-width: 700px) {
        @content;
    }
}
@mixin for-small-mobile {
    @media (max-width: 500px) {
        @content;
    }
}
@mixin for-tiny-mobile {
    @media (max-width: 400px) {
        @content;
    }
}

@mixin for-min-lg {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin for-min-md {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin for-min-sm {
    @media (min-width: 480px) {
        @content;
    }
}
@mixin for-max-lg {
    @media (max-width: 992px) {
        @content;
    }
}
@mixin for-max-md {
    @media (max-width: 768px) {
        @content;
    }
}
@mixin for-max-sm {
    @media (max-width: 480px) {
        @content;
    }
}