
.incorrectData {
    margin-left: 10px;
    cursor: pointer;
    color: #1976d2;
    font-size: 12px;
    &:hover {
        color: orangered;
    }
}
.incorrectActive {
    color: orangered;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        background-color: #1976d2;
        height: 1px;
        width: 100%;
    }
}

.capitalize {
    text-transform: capitalize;
}

.data-content {
    ul.data-topics {
        padding: 0;
        margin: .5rem 0;
        li {
            list-style: none;
            padding: 5px 0;
            .row {
                margin-left: 0;
            }
            .topic-title {
                border: solid 1px #c7c7c79f;
                padding: 7px;
                max-width: 300px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 5px;
                cursor: pointer;
                h2 {
                    font-size: 14px;
                    margin: 0;
                }
            }
            .last {
                border-bottom: none !important;
            }
            .topic-item {
                border-bottom: solid 1px #c7c7c79f;
                padding: 10px;
                width: 100%;
                max-width: 50vw;
                @media (max-width: 450px) {
                 max-width: 100%;   
                 margin-right: 1rem;
                }
                div {
                    display: flex;
                    flex-direction: column;
                    .row {
                        display: flex;
                        gap: 10px;
                        flex-direction: row !important;
                    }
                }
                span {
                    font-size: 13px;
                }
                h3 {
                    font-size: 14px;
                    margin: 0;
                    font-weight: bold;
                    margin-bottom: 3px;
                }
                b {
                    font-weight: 500;
                }
            }
        }
    }
}