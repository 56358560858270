@import './propertyData';
@import './financing';
@import './phoneInput';

.form-quotation-container {
    max-height: 75vh;
}

.custom-form {
    .address-group {
        display: flex;
        gap: 1rem;
        width: 100%;

        .address-form {
            width: 80%;
        }
        .addressNumber-form {
            width: 20%;
        }
    }

    .phone-group {
        display: flex;
        gap: 1rem;
        width: 100%;

        div {
            width: 50%;
        }
    }
}
.bs-stepper .bs-stepper-header .step .step-trigger {
    flex-wrap: wrap !important;
}
.wizard-seller-data, .wizard-buyer-data {
    .bs-stepper-header {
        display: none !important;
    }
}


.content-area-wrapper {
    border: none !important;
}
.container-wizard {
    padding-bottom: 1rem;
}

.label-steps {
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    margin: 1rem 0;
    .css-1hv8oq8-MuiStepLabel-label {
        color: rgb(101, 101, 101)3e3e !important;
    }
    
}

.btn-prev-next {
    margin-top: 5px;
}

.form-quotation-container {
    width: 100%;
    height: 100%;

    small {
        color: red;
        margin-left: 2px;
    }    

    .horizontal-wizard {
        min-width: 100%;
        min-height: 100%;
        margin: 0;
    }
}

.modal-regist-broker {
    small {
        color: red;
    }
}
.bank-data {
    .form-group-container {
        border-left: solid 2px #3944604b;
        padding-left: 10px;
        padding-bottom: 5px;
        margin-top: 4px;
    }
    .agencia-conta-group {
        gap: 0.75rem;
        @media (max-width: 400px) {
            gap: 0.25rem;
            flex-wrap: wrap;
            .form-group:first-child {
                width: 100%;
            }
        }
    }
    .broker-bank-account-group {
        display: flex;
        align-items: center;
        gap: .50rem;
        width: 100%;
        .form-group {
            width: 100%;
        }
        .form-group:first-child {
            width: 75%;
        }
        .form-group:last-child {
            width: 25%;
        }
    }    
}
.group-flex-wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 0.50rem;
    .form-group {
        width: 100%;
    }
    input {
        width: 100%;
    }
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
}

.dark-layout {
    .label-steps {
        .css-1hv8oq8-MuiStepLabel-label {
            color: rgb(173, 173, 173) !important;
        }
    }
    .css-16xfy0z-control, .css-13cymwt-control, .css-t3ipsp-control {
        background-color: transparent !important;
        border-color: #bebebe27 !important;
        .css-1dimb5e-singleValue {
            color: #b8b8b8 !important;
        }
    }
    .css-14h4o58-menu {
        background-color: #283046;
    }
}