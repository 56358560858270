// Variables
@import './variables/myVariables';
@import './variables/variables';

// Login
@import './styles/authentication/login';
@import './styles/authentication/forgotPassword';

// Misc
@import './styles/misc/notAuthorized';
@import './styles/misc/error.scss';
@import './styles/misc/underMaintenance';

// Forms
@import './styles/forms/index';
@import '../../@core/scss/base/plugins/forms/form-wizard.scss';

// Client
@import './styles/client/index';

// .MuiSvgIcon-root.Mui-active {
//   color: #f5789b !important;
// }
// .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
//   color: #ba5672 !important;
// }

.MuiButtonBase-root, .MuiSvgIcon-root, .text-header-quotation {
  color: $primary !important;
}
.color-blue {
  color: #4081c1 !important;
}
// input property-value -- currency BRL
.ant-input {
  color: #5e5e5e !important;
}
.anticon-close-circle svg {
  margin-top: -4.5px !important;
  &:hover {
    color: $danger;
  }
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
// footer
footer {
  position: fixed;
  bottom: 0;
  font-size: 12px;
  span, strong {
    color: rgb(183, 183, 183);
  }
  strong {
    border-bottom: solid 1px rgba(183, 183, 183, 0.468);
    transition: .2s all;
    &:hover {
      color: #4081c1;
    }
  }
  .networks-footer {
    margin-left: 1rem;
  }
}
.navbar-components {
  display: flex;
  align-items: center;
  gap: 6px;
}
.selected-language{
  transition: .1s;
  &:hover {
    color: #4081c1;
  }
}

.vertical-layout {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.header-navbar {
  position: fixed;
  top: 0;
  min-width: 100%;
  padding: 4px 0;
}
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  text-align: center;
  .btn-group-user-options {
    display: flex;
    .btn-tutorial {
      margin-right: 5px;
    }
  }
  @media (max-width: 500px) {
    .logo-rcpm {
      display: none;
    }
    .navbar-nav {
      width: 100%;
      justify-content: space-around;
      flex-wrap: nowrap !important;
      margin: 0;
      .group-theme-language {
        .dropdown-language {
          display: none;
        }
      }
      .btn {
        padding: 6px !important;
        font-size: 12px;
      }
    }
    .demo-inline-spacing {
      display: none;
    }
  }
}
ul.navbar-nav {
  @media (min-width: 400px){
    margin: 0 -15px 0 0 !important;
  }
}
.btn-logout {
  padding: 7.3px;
  white-space: nowrap;
  overflow: hidden;
  min-width: 2rem;
  span {
    opacity: 0;
    transition: .2s;
    margin-right: -2em;
  }
  &:hover {
    color: #b44441 !important;
    border-color: #b44441 !important;
    span {
      opacity: 1;
      margin-right: 0;
      margin-left: 4px;
    }
  }
}
.logo-rcpm {
  max-width: 80px;
  margin-left: 8px;
}
.text-header-quotation {
  text-shadow: 1px 1px 3px #55555543;
  letter-spacing: 2px;
  font-size: 14px;
  animation: maximize 3s alternate;

  @keyframes maximize {
    from {
      transform: scale(1.1);
      letter-spacing: 8px;
    } to {
      transform: scale(1);
      letter-spacing: 2px;
    }
  }
  @media (max-width: 700px) {
    display: none;
  }
}
.app-content {
  margin: 0 !important;
  padding: 8rem 4rem 2rem !important;
  height: 100%;
}

// Global
.logo-bravo {
    max-width: 120px;
    height: auto;
    width: 100%;

    @media (max-width: 767px) {
        max-width: 90px;
    }
}

.rcpm-content-body {
  width: 100%;
}

.label-for-input {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 3px;
}

// Scroll Sidebar e Leads
.sidebar-menu-list, .setScroll {
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important
  }
}

// whatsapp
#link-wpp {
  text-decoration: none;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  animation: animaWpp 2s infinite;
  -webkit-animation: animaWpp 2s infinite;
  -moz-animation: animaWpp 2s infinite;
  -o-animation: animaWpp 2s infinite;
  img {
    max-width: 50px;
  }
}
@keyframes animaWpp {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  } 50% {
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -o-transform: scale(.9);
  } 100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
}
.special-label {
  display: none !important;
}
.gap-25 {
  gap: 0.25rem !important;
}
.gap-50 {
  gap: .50rem !important;
}
.gap-75 {
  gap: 0.75rem !important;
}
.gap-1 {
  gap: 1rem !important;
}

.render-count {
  width: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  left: 1rem;
  top: 8px;
  transition: .2s;

  .btn-hideCount {
    margin-left: 8px;
    cursor: pointer;
    display: none;

    &:hover {
      color: rgb(141, 58, 58);
    }
  }

  @media (max-width: 865px) {
    span {
      display: none;
    }
  }
  @media (max-width: 715px) {
      background: #e3e3e3f3;
      color: #464e5c;
      top: -2rem;
      left: 0;
      padding: 5px 10px 5px 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      .btn-hideCount {
        display: block;
      }
  }
}
@media (max-width: 715px) {
  .hidden-count {
    left: -5.4rem;
  }
}

.alert span {
  word-break: break-word;
}

.footer .btn-cadastrar-indicador {
  color: #df8219;
  font-weight: 500;
}

.MuiButton-root {
  outline: none !important;
}

//footer dark theme
.dark-layout {
  .ant-input-affix-wrapper, .ant-input {
    background: transparent !important;
    color: #dcdcdcb0 !important;
  }
  .ant-input-affix-wrapper {
    border: solid 1px #90909036 !important;
  }

  footer p {
    span:nth-child(1) {
      color: #dcdcdc93 !important;
    }
    span:nth-child(2n) {
      a strong {
        color: #dcdcdc93 !important;
        &:hover {
          color: #4081c1 !important;
        }
      }
    }
  }

  // input select
  .css-166bipr-Input {
    input {
      color: #d9d9d9 !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: #283046;
  }
}

.alert {
  word-break: break-word !important;
}
.flatpickr-input:not(:disabled) {
  background-color: inherit;
}
.modal-space {
  position: absolute;
  z-index: -10;
}