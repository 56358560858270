.tela-forgotPassword {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .resetPassword-container {
        background-color:#ffffff;
    }

    .card-forgot-title {
        text-align: center;
    }

    i {
        font-size: 12px;
        color: red;
    }

    @media (max-width: 991.9px) {
        .resetPassword-container {
            background-color: transparent;

            .resetPassword-area {
                background-color: #ffffff;
                padding: 2rem;
                border-radius: 10px;
            }
        }
    }
}
.reset-password {
    .form-group {
        i {
            font-size: 12px;
            color: red;
        }
    }
}
.reset-password-error {
    animation: borderEffect .8s;
}
@keyframes borderEffect {
    0% {
        box-shadow: none;
    }
    50% {
        box-shadow: 0 0 2px 1px #c5292998;
        padding: 0 4px;
        border-radius: 5px;
    }
    100% {
        box-shadow: none;
    }
}





