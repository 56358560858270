.PhoneInput {
    display: flex;
    gap: 0;
    .PhoneInputCountry {
      width: 2.3em !important;
  
      .PhoneInputCountryIcon {
        position: relative;
        top: -1.5em !important;
        img {
          border: solid 1px #5e5e5e9c;
          width: 1.8em;
          height: auto;
        }
      }
      select {
        cursor: pointer;
        position: relative;
        z-index: 5;
        opacity: 0;
        width: 2.2em;
        height: auto;
      }
    }
    .PhoneInputInput {
      border: none;
      height: 100%;
      background-color: transparent;
      outline: none;
    }
  }
  
  .phoneinput {
    width: 100% !important;
  }
  
  .dark-layout {
    .phoneinput {
      border-color: #74747475;
    }
    .PhoneInputInput {
      color: #bebebe;
    }
}