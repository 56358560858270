.result-rcpm-container {
    .steps-container {
        .timeline {
            .status-description {
                margin: auto 0;
            }
            .activeStep {
                color: #1976d2;
                font-style: italic;
            }
            .finishedStep {
                color: #339d41;
            }
            .step-error {
                color: $danger;
                font-style: italic;
            }
            .step-canceled {
                color: $secondary;
                font-style: italic;
            }
            .MuiTimelineSeparator-root {
                .MuiBox-root {
                    display: none !important;
                }
            }
            li {
                &::before {
                    display: none !important;
                }
            }
            .MuiTypography-root {
                margin-bottom: 0 !important;
            }

            .conector {
                background-color: rgb(204, 204, 204);
                border-radius: 3px;
                margin: 0 1rem;
                &:nth-last-child(1) {
                    display: none !important;
                }
            }

            @media (max-width: 992px) {
                margin-top: 1rem;
                li {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-top: -1em;

                    &:nth-child(1) {
                        .conector-inicial:nth-child(1) {
                            display: none;
                        }
                    }
                    &:nth-last-child(2) {
                        .MuiTimelineConnector-root:nth-last-child(2) {
                            display: none;
                        }
                    }
                    .status-description {
                        display: flex;
                        align-items: center;
                        padding: 0 !important;
                        margin: 0 !important;
                    }
                    .MuiTypography-root {
                        margin-top: -4px !important;
                    }

                }
                .conector {
                    min-height: 40px;
                    width: 2px;
                    margin-top: -1em !important;
                    margin-left: .8em;
                }
            }

            @media (min-width: 992px) {
                display: flex !important;
                flex-direction: row !important;
                align-items: center;

                .content-for-min-lg {
                    display: none !important;
                }

                li {
                    display: flex !important;
                    flex-direction: column !important;
                    justify-content: center !important;

                    .MuiTimelineSeparator-root {
                        display: flex !important;
                        align-items: center !important;
                        flex-direction: row !important;

                        .MuiTimelineConnector-root {
                            display: none !important;
                        }
                        .MuiBox-root {
                            display: inline-block !important;
                            margin-left: 10px !important;

                            p {
                                margin-bottom: 0 !important;
                                margin-top: -3px;
                            }
                        }
                    }
                }
                .conector {
                    min-width: 80px;
                    height: 1.5px;
                }
            }
        }

        .nav-tabs {
            margin: 0 .6rem;
            .nav-item {
                padding: 3px 8px;
                &:first-child {
                    border-right: solid 1px #bfbfbf80;
                    padding-right: 8px;   
                    @media (max-width: 356px) {
                        border-right: none !important;
                        padding-right: none !important;
                    } 
                }
            }
            .nav-link {
                padding: 0 0 4px;
                svg {
                    max-width: 15px;
                    height: auto;
                    margin-right: 3px;
                    margin-top: 2px;
                }
            }
        }
        .tab-content {
            margin: 0 1rem;
            .quote-description {
                @media (max-width: 410px) {
                    margin: 4px 0;
                }
                .demo-inline-spacing {
                    display: none;
                }
                h3 {
                    margin: -8px 0 0 0;
                    font-size: 12px;
                    color: #878787ab;
                    svg {
                        margin-right: 4px;
                        max-width: 11px;
                        max-height: 11px;
                    }
                }
                span {
                    font-size: 12px;
                }
                .btn-results {
                    display: flex;
                    margin-top: 4px;
                    button {
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        gap: 5px;   
                        padding: 1px 6px !important; 
                        svg {
                            max-width: 15px;
                        }
                    }
                }
                .description-proposal-container {
                    .btns-proposta-boleto {
                        margin-top: 6px;
                        .btn {
                            padding: 4px 8px;
                            &:first-child {
                                margin-right: 8px;
                            }
                        }
                    }
                    @media (max-width: 750px) {
                        br {
                            display: none;
                        }
                    }
                    @media (max-width: 400px) {
                        text-align: justify;
                        .btns-proposta-boleto {
                            flex-direction: column;
                            .btn {
                                margin: 3px 0 !important;
                                justify-content: center;
                            }
                        }
                    }
                }
                .policy-description {
                    .btn-see-policy {
                        margin-top: 6px;
                        .btn {
                            justify-content: center;
                            padding: 4px 8px;
                            max-height: 28px;
                            min-width: 100px;
                            flex-wrap: nowrap;
                            white-space: nowrap;
                        }
                    }
                }
            }
    
            .permission-to-issue {
                margin: 5px 0 2rem;
                display: flex;
                gap: 7px;
                svg {
                    max-width: 14px;
                    margin-top: -2px;
                }
                .btn-permission-container {
                    display: flex;
                    gap: 8px;
                    margin-top: -4px;
                    .btn {
                        padding: 5px 10px !important;
                        font-size: 12px;
                        max-height: 28px;
                    }
                    .btn-results {
                        margin-top: 0 !important;
                        border-left: solid 1px rgba(165, 165, 165, 0.397);
                        padding-left: 12px;
                        margin-left: 4px;
                        .btn {
                            div {
                                display: flex;
                                align-items: center;
                            }   
                            // span.loading-quotation {
                            //     margin: 0 6px 0 -9px !important;
                            // }
                            svg {
                                margin: 0 2px 0px 0;
                            }
                            .cotacao-carregada {
                                padding: 0px;
                            }
                        }
                    } 
                    @media (max-width: 410px) {
                        flex-direction: column;
                        .btn-results {
                            border-left: none;
                            border-top: solid 1px rgba(165, 165, 165, 0.397);
                            margin-left: 0;
                            margin-top: 4px !important;
                            padding-left: 0;
                            padding-top: 12px;
                            .btn {
                                justify-content: center;
                                width: 100%;
                            }
                        }
                    }              
                }
            }        
        }
     }

}

.modal-warning {
    min-width: 50%;
    .declaracoes-finais {
        padding: 1.5rem;
        h3 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 1rem;
        }
        input, select {
            cursor: pointer;
        }
        .statement-1, .statement-3 {
            gap: 5px;
            input {
                margin-top: 3px;
                min-width: 15px;
                min-height: 15px;
              }
        }
        #input-statement-2 {
            padding: 4px;
            border-color: #bfbfbf;
        }
        .alert-warning {
            padding: 8px;
            gap: 5px;
            font-size: 13px;
        }
        .had-accident {
            span {
                font-size: 12px;
                font-weight: bold;
            }
            div {
                display: flex;
                label {
                    margin-right: 1em;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }
        }        
    }
}
.dark-layout {
    .modal-warning {
        .declaracoes-finais {
            #input-statement-2 {
                background-color: transparent !important;
                border-color: rgba(255, 255, 255, 0.459) !important;
                color: #fff;
                option {
                    background: #2f3750 !important;
                }
            }
            .had-accident {
                color: #d0d2d6;
            }
        }
    }
}


