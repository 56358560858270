.all-documents {
    .loading-container {
        justify-content: flex-start !important;
        .loading-content {
            padding: 1rem;
        }
    }
    span {
        font-size: 12px;
        margin: 10px 0 !important;
    }
    .documents-found {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 5px;
        .btn {
            padding: 6px 8px;
            font-size: 12px !important;
            white-space: nowrap;
            svg {
                width: 15px;
                height: auto;
            }
        }
    }

}   